<template>
  <v-card outlined class="ma-4">

    <!-- header -->
    <v-toolbar flat color="primary" dense>
      <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Zawodnicy</v-toolbar-title>
      <v-spacer/>
      <span style="width: 300px">
        <v-text-field flat dense hide-details solo v-model="search" label="Imię Nazwisko" @keyup.enter="create()"/>
      </span>
      <v-btn elevation="0" class="ml-2" style="text-transform: unset" @click="create()">
        <v-icon left>mdi-magnify</v-icon>
        Szukaj
      </v-btn>
    </v-toolbar>

    <!-- competitors -->
    <v-simple-table dense
                    :style="[$vuetify.theme.dark ? {'border-bottom': '1px solid #2f2f2f'} : {'border-bottom': '1px solid #e0e0e0'}]">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Zawodnik</th>
          <th class="text-center">ID</th>
          <th class="text-center">Państwo</th>
          <th class="text-center" v-if="$vuetify.breakpoint.smAndUp">Zawody</th>
          <th class="text-center" v-if="$vuetify.breakpoint.smAndUp">Ułożenia</th>
          <th class="text-center" v-if="$vuetify.breakpoint.smAndUp">Medale</th>
          <th class="text-center" v-if="$vuetify.breakpoint.mdAndUp">&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="c in competitors" :key="c.ra_id" style="cursor: pointer"
            @click="$router.push('/competitors/' + c.ra_id)">
          <td class="text-left">{{ c.name }}</td>
          <td class="text-center overline primary--text">{{ $env.APP_MODE === 'ppo' ? c.ra_id : c.wca_id }}</td>
          <td class="text-center">
            <v-img :src="'/flags/' + c.country.toLowerCase() + '.svg'" height="21" width="28" class="ml-auto mr-auto"
                   :style="[$vuetify.theme.dark ? {'border': '1px solid #2f2f2f'} : {'border': '1px solid #e0e0e0'}]"/>
          </td>
          <td class="text-center" v-if="$vuetify.breakpoint.smAndUp"
              :style="[$vuetify.theme.dark ? {'border-left': '2px solid #2f2f2f'} : {'border-left': '2px solid #e0e0e0'}]">
            {{ c.comps }}
          </td>
          <td class="text-center" v-if="$vuetify.breakpoint.smAndUp">{{ c.solves }}</td>
          <td class="text-center" v-if="$vuetify.breakpoint.smAndUp">{{ c.medals }}</td>
          <td class="text-center" v-if="$vuetify.breakpoint.mdAndUp"
              :style="[$vuetify.theme.dark ? {'border-left': '2px solid #2f2f2f'} : {'border-left': '2px solid #e0e0e0'}]">
            <span v-if="c.gold > 0">&nbsp;{{ c.gold }}
            <v-icon color="yellow darken-1">mdi-medal</v-icon></span>
            <span v-if="c.silver > 0">&nbsp;{{ c.silver }}
            <v-icon color="grey darken-1">mdi-medal</v-icon></span>
            <span v-if="c.bronze > 0">&nbsp;{{ c.bronze }}
            <v-icon color="brown darken-1">mdi-medal</v-icon></span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- more -->
    <v-row v-if="/*more*/ false" justify="center" class="ma-4">
      <v-btn outlined color="primary" style="text-transform: unset">Zobacz więcej zawodników</v-btn>
    </v-row>

  </v-card>
</template>

<script>
export default {
  name: 'Competitors',
  data: () => ({
    competitors: [],
    search: '',
    more: true
  }),
  created: function () {
    this.create();
  },
  methods: {
    create() {
      this.$http.post('/api/competitors', {search: this.search, order: 'rank', sort: 'DESC', offset: 0}).then((res) => {
        this.competitors = res.data;
      }).catch((e) => {
        console.log(e);
      });
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #00000010;
}

.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #ffffff10;
}
</style>
